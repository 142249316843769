import { AppBar, Box, styled, Typography } from "@mui/material";

export const NavbarContainer = styled(AppBar)(({ theme }) => ({
  backgroundColor: "white",
  color: "black",
  boxShadow: "none",
  height: "5vh",
}));

export const Logo = styled("img")(({ theme }) => ({
  height: "4vh",
  marginRight: theme.spacing(1),
}));

export const SubtitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "30px",
  textAlign: "left",
  paddingLeft: "20px",
  paddingBottom: "10px",
}));

export const TitleContainer = styled(Box)(({ theme }) => ({}));
