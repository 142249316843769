import unknownModelImage from "./assets/unknown_model.png";

export const compareDeviceInfo = (deviceInfo, userDeviceInfo) => {
  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };

  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!keys2.includes(key)) return false;

      if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        if (!arraysEqual(obj1[key], obj2[key])) return false;
      } else if (!deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  return deepEqual(deviceInfo, userDeviceInfo);
};

const modelToImage = {
  "LR 1005 E": "",
  "LR 1006 G4":
    "https://james.com.uy/wp-content/uploads/2023/10/LR-1006-G4-BL.png",
  "LR 1008": "https://james.com.uy/wp-content/uploads/2023/10/LR-1008-BL.png",
  "LR 516 TA": "",
  "LR 616 TA": "",
  "LR 6900 PLUS": "",
  "LR 8120 PLUS":
    "https://james.com.uy/wp-content/uploads/2023/10/LR_7100Plus_Frente.png",
  "LR 8140 INV":
    "https://james.com.uy/wp-content/uploads/2024/03/LR-8140-INV-BL.png",
  "LR TOP WMT 680":
    "https://james.com.uy/wp-content/uploads/2023/07/2023-LR-WMTJ-680.png",
  "LSE 1016 G3":
    "https://james.com.uy/wp-content/uploads/2023/10/LSE-1016-BL-G3.png",
  "SEM 30":
    "https://james.com.uy/wp-content/uploads/2023/10/SecarropaSEM30_frente.png",
  "SEM 70 D":
    "https://james.com.uy/wp-content/uploads/2023/10/SEM-70D-frente.png",
};

export const obtainModelImageSrc = (modelName) => {
  if (modelName in modelToImage) {
    return modelToImage[modelName];
  }
  return "";
};

export const getModelImageSrc = (model) => {
  const src = obtainModelImageSrc(model);
  return src ? src : unknownModelImage;
};
