import { Box, Typography, styled } from "@mui/material";

export const SelectedModelCard = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "10px",
  marginTop: "20px",
  borderRadius: "10px",
  backgroundColor: "#f5f5f5",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
}));

export const ModelDetails = styled(Box)(({ theme }) => ({
  marginLeft: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

export const ModelLink = styled(Typography)(({ theme }) => ({
  color: "#007bff",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
}));
