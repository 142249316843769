import Webcam from "react-webcam";
import React, { useEffect } from "react";

function isSafari() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes("safari") && !ua.includes("chrome");
}

const WebCamComponent = ({ webcamRef, webcamKey, hasPermission }) => {
  const FACING_MODE_USER = { exact: "user" };
  const FACING_MODE_ENVIRONMENT = { exact: "environment" };

  const videoConstraints = {
    facingMode: isSafari() ? FACING_MODE_USER : FACING_MODE_ENVIRONMENT,
  };

  const [facingMode, setFacingMode] = React.useState(
    isSafari() ? FACING_MODE_USER : FACING_MODE_ENVIRONMENT
  );

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  useEffect(() => {
    if (isSafari()) {
      setTimeout(() => setFacingMode(FACING_MODE_ENVIRONMENT), 1000);
    }
  }, []);

  return (
    <Webcam
      ref={webcamRef}
      screenshotFormat="image/jpeg"
      videoConstraints={{
        ...videoConstraints,
        facingMode,
      }}
      onClick={handleClick}
    />
  );
};

export default WebCamComponent;
