import React from "react";

import { FooterContainer, FooterTypography, Logo } from "./FooterStyles";

const FooterView = () => {
  return (
    <FooterContainer>
      <FooterTypography>
        Powered by
        <Logo
          src={`${process.env.PUBLIC_URL}/manuar-icon.png`}
          alt="Manuar Logo"
        />
        <Logo
          src={`${process.env.PUBLIC_URL}/manuar-text.png`}
          alt="Manuar Logo"
        />
      </FooterTypography>
    </FooterContainer>
  );
};

export default FooterView;
