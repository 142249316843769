import { Box, Typography, styled } from "@mui/material";

export const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  color: "black",
  boxShadow: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "fixed",
  bottom: 0,
  width: "100%",
  height: "2vh",
  paddingBottom: "1.5vh",
  paddingTop: "1.5vh",
}));

export const Logo = styled("img")(({ theme }) => ({
  height: "2vh",
  marginLeft: theme.spacing(1),
}));

export const FooterTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
}));
