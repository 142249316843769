import React from "react";
import { Typography } from "@mui/material";

import { SelectedModelCard, ModelDetails, ModelLink } from "./DeviceCardStyle";

const DeviceCardComponent = ({ imageSrc, altText, text, onClick }) => {
  return (
    <SelectedModelCard>
      <img
        src={imageSrc}
        alt={altText}
        style={{
          maxWidth: "50px",
          maxHeight: "50px",
          borderRadius: "5px",
        }}
      />
      <ModelDetails>
        <Typography variant="body2">{text}</Typography>
        {onClick && (
          <ModelLink variant="body2" onClick={onClick}>
            Ver detalles
          </ModelLink>
        )}
      </ModelDetails>
    </SelectedModelCard>
  );
};

export default DeviceCardComponent;
