import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useLoginSignupLogic = (onLogin, setErrorMessage) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      navigate("/chat/not-started");
    }
  }, [navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage("");
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleLogin = () => {
    if (!email || !password) {
      setErrorMessage(
        "Ambos campos de email y contraseña son obligatorios para iniciar sesión/registrarse."
      );
      return;
    }
    if (!isValidEmail(email)) {
      setErrorMessage("Formato de email inválido.");
      return;
    }
    onLogin(email, password);
    navigate("/chat/not-started");
  };

  const handleSkipLogin = () => {
    if (!email) {
      setErrorMessage(
        "El email es requerido para continuar sin iniciar sesión."
      );
      return;
    }
    if (!isValidEmail(email)) {
      setErrorMessage(
        "Es necesario introducir un e-mail inclusive en modo sin conexión."
      );
      return;
    }
    onLogin(email, null);
    navigate("/chat/not-started");
  };

  return {
    email,
    password,
    handleEmailChange,
    handlePasswordChange,
    handleLogin,
    handleSkipLogin,
  };
};

export default useLoginSignupLogic;
