import React, { useState, useEffect, useRef } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const TypingMessage = ({ message, complete }) => {
  const [displayedMessage, setDisplayedMessage] = useState("");
  const typingSpeed = 15; // Time in milliseconds for each character to appear
  const typedCharactersRef = useRef(0);

  useEffect(() => {
    if (complete) {
      setDisplayedMessage(message); // If the message is complete, show it instantly
      typedCharactersRef.current = message.length;
    } else {
      // Simulate typing only for the new part of the message
      const newCharacters = message.slice(typedCharactersRef.current);

      if (newCharacters.length > 0) {
        let index = 0;
        const interval = setInterval(() => {
          if (index < newCharacters.length) {
            setDisplayedMessage((prev) => prev + newCharacters[index]);
            index += 1;
          } else {
            clearInterval(interval);
            typedCharactersRef.current = message.length; // Update the number of typed characters
          }
        }, typingSpeed); // Typing speed

        return () => clearInterval(interval);
      }
    }
  }, [message, complete]);

  return (
    <Markdown className="markdown-table" remarkPlugins={[remarkGfm]}>
      {displayedMessage}
    </Markdown>
  );
};

export default TypingMessage;
