import { Box, Button, Container, Typography, styled } from "@mui/material";

export const ConfirmationContainer = styled(Container)(({ theme }) => ({
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  maxWidth: "100vw",
  padding: "20px",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "85vw",
  },
}));

export const ConfirmationParagraph = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  whiteSpace: "normal",
  wordWrap: "break-word",
  margin: "20px",
}));

export const ImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "20px 0",
}));

export const DevicePhoto = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  maxHeight: "40vh",
  height: "auto",
  borderRadius: "10px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
}));

export const ModelImages = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  margin: "20px 0",
  justifyContent: "center",
}));

export const ModelContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const ModelImage = styled("img")(({ theme, selected }) => ({
  height: "auto",
  maxHeight: "15vh",
  cursor: "pointer",
  border: selected ? "2px solid #0033a0" : "2px solid transparent",
  borderRadius: "5px",
  transition: "border 0.3s",
}));

export const ModelLabel = styled(Typography)(({ theme }) => ({
  marginTop: "5px",
  textAlign: "center",
  fontSize: "14px",
}));

export const ConfirmationButton = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: "10px",
  margin: "10px 0",
  borderRadius: "5px",
  backgroundColor: "#0033a0",
  color: "white",
  "&:hover": {
    backgroundColor: "#002080",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
