import { Box, IconButton, Button, styled } from "@mui/material";

export const BottomBanner = styled(Box)(({ theme }) => ({
  height: "10vh",
  backgroundColor: "rgba(255, 255, 255, 1)",
  display: "flex",
  position: "absolute",
  bottom: "5vh",
  left: "0px",
  width: "100%",
  justifyContent: "space-around",
  alignItems: "center",
  boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
}));

export const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
}));

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: "#0033a0",
  "&:hover": {
    color: "#002080",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  color: "#0033a0",
  "&:hover": {
    color: "#002080",
  },
  fontSize: "1rem",
  textTransform: "none",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem",
  },
}));
