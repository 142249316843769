import { Button, styled } from "@mui/material";

export const CustomButton = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: "10px",
  borderRadius: "3vw",
  backgroundColor: "#0033a0",
  color: "white",
  "&:hover": {
    backgroundColor: "#002080",
  },
}));

export const SkipButton = styled(Button)(({ theme }) => ({
  padding: "10px 20px",
  border: "none",
  borderRadius: "0.5vw",
  color: "grey",
  cursor: "pointer",
  whiteSpace: "nowrap",
  fontSize: "24px",
  opacity: 1,
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  transition: "opacity 0.5s ease-in-out",
  "&:hover": {
    color: "#0056b3",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "4vw",
  },
}));
