import React from "react";
import { Toolbar, Box, IconButton, Divider } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";

import {
  NavbarContainer,
  Logo,
  TitleContainer,
  SubtitleTypography,
} from "./NavbarStyles";

const NavbarView = ({ onBack, onClose }) => {
  const location = useLocation();
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  // Conditional logo based on subdomain
  const logoSrc =
    subdomain === "james"
      ? `${process.env.PUBLIC_URL}/james-azul.png`
      : `${process.env.PUBLIC_URL}/manuar-text.png`;
  return (
    <>
      <NavbarContainer position="static">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box display="flex" alignItems="center">
            {location.pathname !== "/chat/not-started" && (
              <IconButton edge="start" color="inherit" onClick={onBack}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <Logo src={logoSrc} alt="Logo" />
          </Box>
          <IconButton edge="end" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </NavbarContainer>
      <TitleContainer>
        <SubtitleTypography>Asistente instantáneo de IA</SubtitleTypography>
        <Divider />
      </TitleContainer>
    </>
  );
};

export default NavbarView;
