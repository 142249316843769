import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

import WebcamComponent from "../WebCam/WebCamView";

import useCameraUploadLogic from "./UploadLogic";
import {
  CustomButton,
  CustomIconButton,
  LoadingOverlay,
  BottomBanner,
} from "./UploadStyles";

const IconWithLabel = ({
  icon,
  label,
  onClick,
  disabled = false,
  component = "button",
}) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <CustomIconButton
      onClick={onClick}
      disabled={disabled}
      component={component}
    >
      {icon}
    </CustomIconButton>
    <CustomButton onClick={onClick} disabled={disabled} component={component}>
      {label}
    </CustomButton>
  </Box>
);

const CameraUploadView = ({
  photo,
  setPhoto,
  onPhotoSubmit,
  displayWarning,
  loading,
  setLoading,
}) => {
  const [webcamKey, setWebcamKey] = useState(0);
  const { hasPermission, webcamRef, handlePhotoSubmit } = useCameraUploadLogic(
    photo,
    setPhoto,
    onPhotoSubmit,
    displayWarning,
    setWebcamKey
  );

  const handlePhotoSubmitWithLoading = () => {
    setLoading(true);
    handlePhotoSubmit();
  };

  return (
    <Box style={{ width: "100vw" }}>
      {loading && (
        <LoadingOverlay>
          <CircularProgress size={60} />
        </LoadingOverlay>
      )}

      <WebcamComponent
        webcamRef={webcamRef}
        webcamKey={webcamKey}
        hasPermission={hasPermission}
      />

      <BottomBanner>
        <IconWithLabel
          icon={
            loading ? (
              <CircularProgress size={24} />
            ) : (
              <PhotoCameraIcon fontSize="large" />
            )
          }
          label="Tomar Foto"
          onClick={handlePhotoSubmitWithLoading}
          disabled={loading}
        />
      </BottomBanner>
    </Box>
  );
};

export default CameraUploadView;
