import React, { useRef, useEffect, useState } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CameraAlt,
  Upload,
  PanToolAlt,
  ContentCopy,
  Mic,
  Stop,
  Send,
} from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";

import useChatLogic from "./ChatLogic";
import TypingMessage from "./TypingMessage";
import {
  ChatContainer,
  MessagesContainer,
  MessageBox,
  MessageIcon,
  MessageText,
  CopyButton,
  InputContainer,
  TopSectionContainerSubtitle,
  StyledInputBase,
  SidebarContainer,
  TopSectionContainer,
  ActionButton,
} from "./ChatStyles";

const ChatView = ({
  deviceInfo,
  sendMessage,
  sendAudio,
  getDeviceGlossary,
  confirmedByUser,
  setConfirmedByUser,
  setErrorMessage,
  photo,
  setPhoto,
}) => {
  const {
    messages,
    input,
    setInput,
    handleSend,
    toggleRightSidebar,
    showRightSidebarReal,
    deviceInfoContent,
    placeholder,
    loading,
    handleUploadAudio,
  } = useChatLogic(
    sendMessage,
    sendAudio,
    getDeviceGlossary,
    confirmedByUser,
    setConfirmedByUser,
    setErrorMessage,
    deviceInfo
  );
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [audioHistory, setAudioHistory] = useState([]);
  const [timeLeft, setTimeLeft] = useState(30);
  const canvasRef = useRef(null);
  const messagesEndRef = useRef(null);
  const audioContextClosed = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!canvasRef.current) return;
    if (!audioHistory) return;
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;
    canvasCtx.clearRect(0, 0, width, height);
    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = "#0033a0";

    canvasCtx.beginPath();
    const sliceWidth = (width * 1.0) / audioHistory.length;
    let x = 0;
    for (let i = 0; i < audioHistory.length; i++) {
      const v = audioHistory[i] / 128.0;
      const y = (v * height) / 2;
      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }
      x += sliceWidth;
    }
    canvasCtx.lineTo(canvas.width, canvas.height / 2);
    canvasCtx.stroke();
  }, [audioHistory]);

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      const analyserNode = audioCtx.createAnalyser();
      analyserNode.fftSize = 2048;
      const source = audioCtx.createMediaStreamSource(stream);
      source.connect(analyserNode);
      setAudioContext(audioCtx);
      setAudioHistory([]);
      audioContextClosed.current = false;

      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      let recorderIsPlaying = true;

      recorder.ondataavailable = (event) => {
        const audioBlob = event.data;
        recorderIsPlaying = false;
        handleUploadAudio(audioBlob);
      };

      recorder.start();
      setRecording(true);

      let timer = 30;
      setTimeLeft(timer);
      const timeoutTimer = () => {
        timer -= 1;
        setTimeLeft(timer);
        if (timer <= 0 || !recorderIsPlaying) {
          recorder.stop();
          setRecording(false);
        } else {
          setTimeout(timeoutTimer, 1000);
        }
      };
      timeoutTimer();

      const draw = () => {
        if (!canvasRef.current || !analyserNode) return;
        const canvas = canvasRef.current;
        const width = canvas.width;
        const dataArray = new Uint8Array(analyserNode.frequencyBinCount);
        analyserNode.getByteTimeDomainData(dataArray);

        setAudioHistory((prevHistory) => {
          const newHistory = [...prevHistory, ...Array.from(dataArray)];
          if (newHistory.length > width) {
            newHistory.splice(newHistory.length - width, newHistory.length);
          }
          return [...newHistory];
        });
        requestAnimationFrame(draw);
      };
      draw();
    } catch (err) {
      console.error("Error starting audio recording:", err);
    }
  };

  const handleStopRecording = () => {
    mediaRecorder.stop();
    setRecording(false);
    if (audioContext && !audioContextClosed.current) {
      audioContext.close();
      audioContextClosed.current = true;
    }
  };

  const handleCopyMessage = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Text copied to clipboard");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const inputFileRef = useRef(null);

  const handleFileClick = () => {
    inputFileRef.current.click();
  };

  const handlePhotoChangeWithLoading = (e) => {
    if (!photo) {
      setPhoto(e.target.files[0]);
    }
  };

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const logoChicoSrc =
    subdomain === "james"
      ? `${process.env.PUBLIC_URL}/james_logo_chico.png`
      : `${process.env.PUBLIC_URL}/manuar-icon.png`;

  return (
    <>
      <ChatContainer>
        {location.pathname === "/chat/not-started" && subdomain == "james" && (
          <TopSectionContainer
            sx={{ paddingBottom: "10vh", paddingTop: "5vh" }}
          >
            <Typography variant="h6">Iniciar conversación</Typography>
            <TopSectionContainerSubtitle
              variant="body2"
              sx={{ marginBottom: "2vh", marginTop: "2vh" }}
            >
              Elige una de las opciones o escribe tu consulta directamente en el
              chat.
            </TopSectionContainerSubtitle>
            <ActionButton
              variant="contained"
              startIcon={<CameraAlt />}
              onClick={() => navigate("/upload")}
              sx={{ marginBottom: "2vh", marginTop: "2vh" }}
            >
              Tomar foto
            </ActionButton>
            <ActionButton
              variant="contained"
              startIcon={<Upload />}
              onClick={handleFileClick}
              sx={{ marginBottom: "2vh", marginTop: "2vh" }}
            >
              <input
                type="file"
                ref={inputFileRef}
                onChange={handlePhotoChangeWithLoading}
                accept="image/*"
                hidden
              />
              Subir imagen
            </ActionButton>
            <ActionButton
              variant="contained"
              startIcon={<PanToolAlt />}
              sx={{ marginBottom: "2vh", marginTop: "2vh" }}
              onClick={() => navigate("/confirm/no-photo")}
            >
              Cargar manualmente
            </ActionButton>
          </TopSectionContainer>
        )}
        <MessagesContainer>
          {messages.map((msg, index) => {
            // Split the message if it contains the [new_message] tag
            if (!msg.message) {
              msg.message = "";
            }
            const parts = msg.message.split("[new_message]");
            return (
              <React.Fragment key={index}>
                {parts.map((part, idx) => (
                  <MessageBox key={`${index}-${idx}`} sender={msg.sender}>
                    <MessageIcon>
                      <img
                        src={
                          msg.sender === "user"
                            ? `${process.env.PUBLIC_URL}/user.png`
                            : logoChicoSrc
                        }
                        alt="icon"
                      />
                    </MessageIcon>
                    <MessageText sender={msg.sender}>
                      {msg.attachments && msg.attachments.length > 0 && (
                        <Box mt={1}>
                          {msg.attachments.map(
                            (attachment, attachmentIndex) => (
                              <React.Fragment key={attachmentIndex}>
                                {attachment.type === "image" && (
                                  <img
                                    src={`data:image/jpeg;base64,${attachment.path}`}
                                    alt="attachment"
                                    style={{
                                      maxWidth: "100%",
                                      marginTop: "8px",
                                      maxHeight: "50vh",
                                    }}
                                  />
                                )}
                                {attachment.type === "audio" && (
                                  <Box mt={1}>
                                    <audio
                                      controls
                                      src={`data:audio/ogg;base64,${attachment.path}`}
                                    >
                                      Your browser does not support the audio
                                      element.
                                    </audio>
                                    <p>
                                      <strong>Transcripción:</strong>
                                    </p>
                                  </Box>
                                )}
                              </React.Fragment>
                            )
                          )}
                        </Box>
                      )}
                      {part === "..." ? (
                        placeholder
                      ) : (
                        <TypingMessage
                          message={part}
                          complete={msg.complete || msg.sender === "user"} // Message is typed only if it's not yet complete
                        />
                      )}
                      <CopyButton
                        onClick={() => handleCopyMessage(part)}
                        sender={msg.sender}
                      >
                        <ContentCopy
                          fontSize="small"
                          style={{ color: "gray" }}
                        />
                      </CopyButton>
                    </MessageText>
                  </MessageBox>
                ))}
              </React.Fragment>
            );
          })}
          <div ref={messagesEndRef} />
        </MessagesContainer>
        <InputContainer>
          {recording && (
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <canvas
                ref={canvasRef}
                width="500"
                height="50"
                style={{
                  width: "90%",
                  height: "50px",
                  backgroundColor: "#f2f2f2",
                }}
              />
              <Typography
                variant="h6"
                color="textSecondary"
                style={{ marginLeft: "10px" }}
              >
                {timeLeft}s
              </Typography>
            </div>
          )}
          {!recording && (
            <StyledInputBase
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Escribe tu mensaje..."
            />
          )}
          {!recording && input.length > 0 && (
            <IconButton onClick={handleSend} disabled={loading}>
              <Send style={{ color: "black" }} />
            </IconButton>
          )}
          {input.length === 0 && (
            <IconButton
              onClick={recording ? handleStopRecording : handleStartRecording}
              disabled={loading}
            >
              {recording ? (
                <Stop style={{ color: "red" }} />
              ) : (
                <Mic style={{ color: "black" }} />
              )}
            </IconButton>
          )}
        </InputContainer>
      </ChatContainer>
      <SidebarContainer
        position="right"
        visible={showRightSidebarReal.toString()}
      >
        <IconButton
          sx={{ position: "absolute", top: "10px", right: "10px" }}
          onClick={() => toggleRightSidebar(false)}
        >
          &times;
        </IconButton>
        <Box className="device-info">
          <Markdown className="markdown-table" remarkPlugins={[remarkGfm]}>
            {deviceInfoContent}
          </Markdown>
        </Box>
      </SidebarContainer>
    </>
  );
};

export default ChatView;
