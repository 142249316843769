import React from "react";
import { Box, TextField } from "@mui/material";

import { DescTypograhpy } from "../GlobalStyles";

import useLoginSignupLogic from "./LoginLogic";
import { CustomButton, SkipButton } from "./LoginStyles";

const LoginSignupView = ({ onLogin, setErrorMessage }) => {
  const {
    email,
    password,
    handleEmailChange,
    handlePasswordChange,
    handleLogin,
    handleSkipLogin,
  } = useLoginSignupLogic(onLogin, setErrorMessage);

  return (
    <>
      <Box style={{ margin: "3vw" }}>
        <DescTypograhpy style={{ marginTop: "20px" }}>
          Iniciar conversación
        </DescTypograhpy>
        <TextField
          type="email"
          label="E-mail"
          value={email}
          onChange={handleEmailChange}
          fullWidth
          margin="normal"
          variant="outlined"
          required
        />
        <Box
          className={`${email ? "show" : "no-show"}`}
          sx={{
            maxHeight: email ? "200px" : "0",
            opacity: email ? 1 : 0,
            width: "100%",
            transition:
              "max-height 0.5s ease-in, opacity 0.5s ease-in, width 0.5s ease-in",
          }}
        >
          <TextField
            type="password"
            label="Contraseña (requerida para iniciar sesión/registrarse)"
            value={password}
            onChange={handlePasswordChange}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Box className="button-group">
            <CustomButton onClick={handleLogin}>
              Iniciar sesión/Registrarse
            </CustomButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            maxHeight: email ? "200px" : "0",
            opacity: email ? 1 : 0,
            width: "100%",
            transition:
              "max-height 0.5s ease-in, opacity 0.5s ease-in, width 0.5s ease-in",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SkipButton onClick={handleSkipLogin}>
            &gt; Continuar sin conectarse
          </SkipButton>
        </Box>
      </Box>
    </>
  );
};

export default LoginSignupView;
