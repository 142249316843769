import {
  Box,
  IconButton,
  InputBase,
  Button,
  Typography,
  styled,
} from "@mui/material";

export const ChatContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "77vh",
  padding: 0,
}));

export const TopSectionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(2),
  textAlign: "center",
  "& > *": {
    marginBottom: theme.spacing(2),
  },
}));

export const TopSectionContainerSubtitle = styled(Typography)(({ theme }) => ({
  background: "#F5F5F5",
  borderRadius: "16px",
  fontFamily: "Poppins",
  fontWeight: 500,
  color: "#9E9E9E",
  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "center",
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  width: "90%",
  padding: theme.spacing(1.5),
  borderRadius: "10px",
  backgroundColor: "white",
  border: "1px solid #ccc",
  color: "black",
  "&:hover": {
    backgroundColor: "#f2f2f2",
  },
}));

export const MessagesContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: "auto",
  padding: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "50px", // Increase this to 90px or 100px
    marginBottom: "5vh", // Add this to make sure it doesn't overlap with the InputContainer
  },
}));

export const MessageBox = styled(Box)(({ theme, sender }) => ({
  display: "flex",
  alignItems: "flex-start",
  margin: theme.spacing(1, 0),
  flexDirection: sender == "user" ? "row-reverse" : "row",
  textAlign: sender == "user" ? "right" : "left",
}));

export const MessageIcon = styled(Box)(({ theme }) => ({
  width: "30px",
  height: "30px",
  margin: theme.spacing(0, 1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  backgroundColor: "#fff",
  boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
  "& img": {
    width: "70%",
    height: "70%",
    objectFit: "cover",
  },
}));

export const MessageText = styled(Box)(({ theme, sender }) => ({
  maxWidth: "80%",
  backgroundColor: sender == "user" ? "#d1e7dd" : "#f2f2f2",
  color: sender == "user" ? "#0f5132" : "black",
  borderRadius: "10px",
  padding: theme.spacing(1),
  wordWrap: "break-word",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  position: "relative", // This is required for the position of the copy button
}));

export const CopyButton = styled(IconButton)(({ theme, sender }) => ({
  position: "absolute",
  top: "-10px",
  right: sender == "user" ? "auto" : "-10px",
  left: sender == "user" ? "-10px" : "auto",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1),
  backgroundColor: "white",
  height: "10vh",
  width: "100%",
  boxSizing: "border-box",
  borderTop: "1px solid #ddd",
  position: "fixed",
  bottom: "4vh",
  zIndex: 1000,
}));

export const StyledInputBase = styled(InputBase)(({ theme, recording }) => ({
  flex: 1,
  height: "75%",
  padding: theme.spacing(1),
  borderRadius: "3vw",
  boxSizing: "border-box",
  marginRight: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#f2f2f2",
}));

export const SidebarContainer = styled(Box)(({ theme, position, visible }) => ({
  position: "fixed",
  top: 0,
  bottom: 0,
  width: "25%",
  transition: "transform 0.3s ease-in-out",
  zIndex: 1000,
  overflowY: "auto",
  backgroundColor: "white",
  boxShadow: "0 0 3vw rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  padding: "20px",
  transform:
    visible === "true"
      ? "translateX(0)"
      : position === "left"
      ? "translateX(-100%)"
      : "translateX(100%)",
  [theme.breakpoints.down("sm")]: {
    width: "75%",
    height: "80%",
    transform:
      visible === "true"
        ? "translate(-50%, -50%)"
        : position === "left"
        ? "translate(-250%, -50%)"
        : "translate(250%, -50%)",
    left: "50%",
    top: "50%",
  },
  left: position === "left" ? 0 : "auto",
  right: position === "right" ? 0 : "auto",
}));
