import { useState, useRef, useEffect } from "react";
import Pica from "pica";

const useCameraUploadLogic = (
  photo,
  setPhoto,
  displayWarning,
  setWebcamKey
) => {
  const [hasPermission, setHasPermission] = useState(true);
  const webcamRef = useRef(null);
  const pica = new Pica();

  useEffect(() => {
    requestCameraPermission();
  }, [displayWarning]);

  const requestCameraPermission = async () => {
    let permission = false;
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      permission = true;
    } catch (err) {
      if (!`${err}`.includes("NotReadableError")) {
        permission = false;
      } else {
        permission = true;
      }
    }
    setHasPermission(permission);
    setWebcamKey((prevKey) => prevKey + 1);
  };

  const handlePhotoSubmit = async () => {
    if (!photo && webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      const blob = dataURItoBlob(imageSrc);
      const file = new File([blob], "webcam_image.jpg", { type: blob.type });
      setPhoto(file);
    }
  };

  const dataURItoBlob = (dataURI) => {
    if (!dataURI) {
      return null;
    }
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return {
    hasPermission,
    webcamRef,
    handlePhotoSubmit,
  };
};

export default useCameraUploadLogic;
